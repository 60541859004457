import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ValdemortModule } from 'ngx-valdemort';
import { StartComponent } from './pages/start/start.component';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { DragulaModule } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './widgets/loader/loader.component';
import { TokenInterceptor } from './services/token.interceptor';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { ConfirmationModalComponent } from './widgets/confirmation-modal/confirmation-modal.component';

if (window.location.origin.indexOf('localhost') === -1) {
  Sentry.init({
    dsn: 'https://8b4f037ff0d845d9a6e2c47553ca0b4a@o494649.ingest.sentry.io/6169362',
    environment: environment.production ? 'prod' : 'develop',
    integrations: [
      new Integrations.BrowserTracing({
        // tracingOrigins: ["localhost", "https://yourserver.io/api"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    LoaderComponent,
    ConfirmationModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ValdemortModule,
    DragulaModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LfEZ8UkAAAAAGFSCD7Fv1AhwYg7rMA7B1yMesmt',
      } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
